jQuery.extend({
    camelize: function(s) {
        var parts = s.split(/_|-/),
            i = 0;

        for (; i < parts.length; i++ ) {
            if (i == 0) continue;
            parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].substr(1);
        }

        return parts.join('');
    }
});