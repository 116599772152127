/**
 * jQuery Shake plugin
 * @author Robin Ebers <robin.ebers@gmail.com>
 */

(function($){
    $.fn.shake = function() {
        this.each(function(i) {
            var $this = $(this),
                marginLeft = parseFloat($this.css('marginLeft')),
                moveMargin = 5,
                duration = 30,
                times = 3;

            // Making sure that the element is positioned relatively
            $this.css({ position: 'relative' });

            for (var x = 1; x <= times; x++) {
                $this
                    .animate({
                        marginLeft: marginLeft-moveMargin
                    }, duration)
                    .animate({
                        marginLeft: marginLeft
                    }, duration)
                    .animate({
                        marginLeft: marginLeft+moveMargin
                    }, duration)
                    .animate({
                        marginLeft: marginLeft
                    }, duration);
            }
        });

        return this;
    }
})(jQuery);
