/**
* jQuery Auto Complete DrowDown
* Implements a auto complete ajax based dropdown.
*
* @author Javier Cobos
* @date 06/02/2014
*
* Update - enable #html click propagation to #document also
* @author  Bogdan Gersak
* @date 10/24/2014
*/

;(function(d){

    d.fn.autoCompDrop = function(params) {

        // singleton per element
        if(this.data("autoCompleteInit")) {return this;}

        // variables
        var $this = $(this),
        _this = this,
        ajaxR,
        $dropDown,
        selectedElement = 0;

        // Default settings
        var defaultValues = {
            requestURL : "",
            searchParamName : "search",
            extraRequestParameters : {},
            minChars : 3,
            onSelect : function(){},
            onEnter : false,
            liContentTemplate : function(){return "";},
            guideElement : null,
            baseTemplate : raceBetsJS.application.templates.autoCompleteDrop.base,
            onFailMsg : raceBetsJS.i18n.data.labelNothingFound,
            extraClass : "",
            cross : false

        };

        $this.data("autoCompleteInit",true);
        params = $.extend( defaultValues, params);

        // DOM Elements
        $dropDown = $(params.baseTemplate(params)).hide().appendTo("body");
        $dropDown.data("empty",true);
        $ul = $dropDown.find("ul");

        alignDrop();

        function alignDrop() {
            $dropDown.css({
                "width" : params.guideElement.outerWidth() - parseInt($(".input-wrapper").css("border-left-width").replace('px','')) - parseInt($(".input-wrapper").css("border-right-width").replace('px','')),
                "top" : params.guideElement.offset().top + params.guideElement.outerHeight(),
                "left" : params.guideElement.offset().left
            });
        }


        function showDrop() {
            alignDrop();
            $dropDown.show();
            $ul.scrollTop(0);
            resetKeyNavigation();
        }


        function emptyDropDown() {
            $ul.html('');
            resetKeyNavigation()
            $dropDown.data("empty",true).hide();
        }

        function resetKeyNavigation() {
            $ul.find("li").removeClass("highlight");
            selectedElement = 0;
        }

        function updateKeyNavigation() {
            var $li;

            if(!$dropDown.data("empty") && $dropDown.is(":visible") && selectedElement >0 && selectedElement <= $ul.find('li').length){
                $li = $($ul.find("li").get(selectedElement-1));
                $ul.find("li").removeClass("highlight");
                $li.addClass("highlight");

                $ul.scrollTop(($ul.scrollTop() + ($li.position().top - $ul.position().top) - ($ul.height()/2) + ($li.height()/2) ));
            }
            $this.val($this.val());
        }

        function bindEvents() {
            // Events
            var requestInProgress = false,
            requestTimeout,
            liNumber;

            if(params.cross) {
                params.cross.on("click",function(){
                    emptyDropDown();
                    $this.val("");
                    $(this).hide();
                    $this.focus();
                });
            }

            $this.on("keyup",function(ev){

                ev.stopPropagation();
                ev.preventDefault();

                var val = $(this).val(),
                data = $.extend({},params.extraRequestParameters);
                val = $.trim(val);

                if(params.cross) {
                    if(val == '') {
                        params.cross.hide();
                    } else {
                        params.cross.show();
                    }
                }


                // enter key
                if (ev.keyCode == 13) {
                    clearTimeout(requestTimeout);

                    if(selectedElement > 0) {
                        $($ul.find("li").get(selectedElement -1 )).trigger("click");
                    } else {

                        if(!params.onEnter) {
                            if(!$dropDown.data("empty") && $dropDown.is(":visible")){
                                $ul.find("li").first().trigger("click");
                            }
                        }else {
                             params.onEnter();
                        }
                    }

                    $dropDown.hide();
                    return;
                }

                // up arrow
                if(ev.keyCode == 38) {

                    if(!$dropDown.data("empty") && $dropDown.is(":visible")){
                        selectedElement = (selectedElement <= 0) ? 0 : (selectedElement - 1);
                        updateKeyNavigation();
                    }

                    return;
                }

                // down arrow
                if(ev.keyCode == 40) {
                    if(!$dropDown.data("empty") && $dropDown.is(":visible")){
                        liNumber = $ul.find("li").length;
                        selectedElement = (selectedElement >= liNumber ) ? liNumber : (selectedElement + 1);
                        updateKeyNavigation();
                    }
                    if(!$dropDown.data("empty") && !$dropDown.is(":visible")) {
                        showDrop();
                    }
                    return;
                }

                if(val.length >= params.minChars) {

                    data[params.searchParamName] = val;

                    // setTimeout to avoid multiple requests in the same moment
                    clearTimeout(requestTimeout);
                    requestTimeout = setTimeout(function(){

                        if (requestInProgress === false) {
                            requestInProgress = true;
                            $.ajax({
                                url: params.requestURL, // @todo correct ajax url
                                type: 'get',
                                data: data,
                                complete: function() {
                                    requestInProgress = false;
                                },
                                success: function(_data) {
                                    var $li;
                                    $ul.html("");

                                    if(_data.length > 0) {
                                        // Render template
                                        $.each(_data,function(i,obj){
                                            $li = $(params.liContentTemplate({runner:obj})).addClass( (i%2 ==0) ? "odd" : "even" );

                                            $li.find("span.repalable-field").html($li.find("span.repalable-field").html().replace(new RegExp("("+val+")","gi"),"<b>$&</b>") );
                                            $ul.append($li);
                                            $li.on("click",function(){ $dropDown.hide(); params.onSelect(obj);  });
                                        });
                                    } else {
                                        $li = $(raceBetsJS.application.templates.autoCompleteDrop.liTemplates.label({label:params.onFailMsg})).addClass("disabled");
                                        $ul.append($li);
                                    }

                                    if($ul.children().length > 0) {
                                        $dropDown.data("empty",false);
                                        showDrop();
                                    }
                                }
                            });
                        }

                    },300);

                } else {
                    $ul.html("");
                    $dropDown.data("empty",true).hide();
                }
            });

            // if the user clicked out and click on again
            $this.on("click",function(){
                if(!$dropDown.data("empty")){
                    showDrop();
                }

            });

            // When you click outside dropdown, hide it, unless its input or dropdown
            $('html').click(function(e) {
                if( (!$.contains($dropDown[0],e.target)) && (e.target != _this[0] ) ){
                    $dropDown.hide();
                    /* Commented this line to enable event propagation to #document. */
                    //e.stopPropagation();
                }
            });
        }

        bindEvents();

        // keep the chain
        return $this;
    }

})(jQuery);
