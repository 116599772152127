/**
* jQuery Pub Sub
* Implements a Pub/Sub pattern. The main idea is the creation of information channels where modules can subscribe to.
* This is going to be resourceful for the node.js implementation, providing us the platform for stablish the communication
* between the node.js server, the main connection class, and the java server
*
* @author Reviewed by Javier Cobos
* @todo Maybe it will be a good idea to implement the possibility of passing the context of the callback as parameter
* @date 06/03/2013
*/

;(function(d){
    var cache = {};

    /**
    * $.publish
    * This function publish information in an specific channel.
    * @param String topic   Channel.
    * @param args           Arguments for the callback function.
    *
    */
    d.publish = function(/* String */topic, /* Array? */args){
        try{
            d.each(cache[topic], function(){
                this.apply(d, args || []);
            });
        } catch (err) {
            // handle this error
            //console.log(err);
        }
    };

    /**
    * $.subscribe
    * Allows to subscribe to a specific channel and set a callback to be call when information arrives.
    * @param String topic   Channel.
    * @param args           Callack to be call using the parameters passed in the publish function.
    * @return Array         Array containing the channel and the callback.
    */
    d.subscribe = function(/* String */topic, /* Function */callback){
        if(!cache[topic]){
            cache[topic] = [];
        }
        cache[topic].push(callback);
        return [topic, callback]; // Array
    };

    /**
    * $.unsubscribe
    * Allows to unsubscribe from a specific channel.
    * @param Array topic   Channel.
    *
    */
    d.unsubscribe = function(/* Array */handle){
        var t = handle[0];
        cache[t] && d.each(cache[t], function(idx){
            if(this == handle[1]){
                cache[t].splice(idx, 1);
            }
        });
    };

})(jQuery);