/**
* Checks if date is full age
*/
$.validator.addMethod('isFullAge', function(value, element) {
    var mydate = new Date(value);
    var currdate = new Date();

    currdate.setFullYear(currdate.getFullYear() - 18);
    return !((currdate - mydate) < 0);
});

/**
* Mobile Number
*/
$.validator.addMethod('isMobileNum', function(value, element) {
    value = value.replace(/ |-/g, '');
    return (value.match(/^\+\d{5,20}$/) || value.match(/^00\d{5,20}$/));
});